/* ProductWidgets.css */

/* Container Styling for Product Widget */
.chatbot-product-widget {
    font-family: "Arial", sans-serif;
    background-color: #FDF0DD;
    color: #4A2C1D;
    border-radius: 10px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin: 10px 0;
}

/* Heading Styling */
.chatbot-product-widget h3 {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 700;
    color: #FD7E4B;
}

/* Product List Styling */
.product-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Product Item Styling */
.product-item {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #E86A33;
}

.product-item:last-child {
    border-bottom: none;
}

/* Product Image Styling */
.product-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 12px;
}

/* Product Info Styling */
.product-info {
    text-align: left;
}

/* Product Title */
.product-info h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

/* Product Description */
.product-info p {
    margin: 4px 0;
    font-size: 14px;
}

/* Price Styling */
.product-info p:last-child {
    font-size: 14px;
    font-weight: bold;
    color: #E86A33;
}

/* Responsive Design */
@media (max-width: 480px) {
    .product-item {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .product-image {
        margin-bottom: 8px;
    }
}
