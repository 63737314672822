/* ChatFoodCard.css */

.chat-food-card {
    background-color: #FDF0DD;
    border-radius: 10px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
    margin: 8px 0;
    font-family: "Arial", sans-serif;
    color: #4A2C1D;
    max-width: 350px;
  }
  
  .chat-food-card-header {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .chat-food-card-img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .chat-food-card-info {
    flex: 1;
    text-align: left;
  }
  
  .chat-food-card-info h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }
  
  .chat-food-card-info p {
    margin: 4px 0;
    font-size: 14px;
  }
  
  .chat-food-card-price {
    font-weight: bold;
    color: #E86A33;
  }
  
  .chat-food-card-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    gap: 10px;
  }
  
  .chat-qty-btn {
    background-color: #FD7E4B;
    color: white;
    border: none;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .chat-qty-display {
    font-size: 16px;
    font-weight: bold;
  }
  
  .chat-add-to-cart-btn {
    background-color: #FD7E4B;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 8px 12px;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .chat-add-to-cart-btn:hover {
    background-color: #e06d42;
  }
  
  .chat-food-card-desc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }