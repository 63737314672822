.react-chatbot-kit-chat-container {
    width: 390px;
    height: 430px; 
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px; 
    overflow: hidden;
}

.react-chatbot-kit-chat-inner-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.react-chatbot-kit-chat-message-container {
    flex-grow: 1;
    overflow-y: auto;
    /* padding-bottom: 40px;  */
    /* max-height: 370px;  */
    background-color: #FDF0DD;
    flex-grow: 1;
    /* overflow-y: auto; */
    padding-bottom: 40px;
    max-height: 370px;
}

.react-chatbot-kit-chat-input-container {
    background-color: white;
    padding: 10px;
    display: flex;
    align-items: center;
  
}

.react-chatbot-kit-chat-container {
    /* background: linear-gradient(135deg, #AA076B, #61045F); */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    /* border: 1px solid #fff; */
    color: white;
}

.react-chatbot-kit-chat-message-container {
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
}

.react-chatbot-kit-chat-message-container::-webkit-scrollbar {
    width: 6px;
}

.react-chatbot-kit-chat-message-container::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
}

.react-chatbot-kit-chat-input-container {
    background-color: white;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
}

.react-chatbot-kit-chat-input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 14px;
    color: #333;
    padding: 8px;
    border-radius: 5px;
}

.chatbot-widget-btn {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: none;
}

.chatbot-widget-btn:hover {
    opacity: 0.8;
}


/* .react-chatbot-kit-chat-message-container {
    background-color: #FDF0DD;
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: 40px;
    max-height: 500px;
} */

/* User Messages (Right Side) */
.react-chatbot-kit-user-chat-message {
    background-color:#fff !important;
    color: #525252;
    border-radius: 8px;
    padding: 8px 12px;
    margin: 5px 10px;
}
/* Send Button */
.react-chatbot-kit-chat-btn-send {
    background-color: #FD7E4B !important;  /* Change button color */
    color: white !important;
    font-weight: bold;
    border-radius: 20px !important;  /* Increase border radius */
    padding: 8px 12px;
    transition: 0.3s ease-in-out;
    border: none;
}

/* Hover Effect */
.react-chatbot-kit-chat-btn-send:hover {
    background-color: #f19470 !important;  /* Darker orange on hover */
}

/* User Messages */
.react-chatbot-kit-user-chat-message {
        /* background-color: #F58549 !important; 
        color: #4A2C1D !important;  */
        background-color: #fff !important; 
        color: #F58549 !important; 
        font-weight: 600;
        font-size: 14px;
        font-family: "Arial", sans-serif;
}




.react-chatbot-kit-chat-bot-message {
    background-color: #FD7E4B !important; /* Main theme color */
    color: #FFFFFF !important; /* White text for contrast */
    font-weight: 600;
    font-size: 14px;
    font-family: "Arial", sans-serif;
    margin-left: 10px;
}

/* Bot Message Arrow (Left) */
.react-chatbot-kit-chat-bot-message-arrow {
    border-right: 8px solid #FD7E4B !important; /* Match bot message background */
}

/* User Message Arrow (Right) */
.react-chatbot-kit-user-chat-message-arrow {
    border-left: 8px solid #fff !important; /* Match user message background */
}


