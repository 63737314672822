.ordered-container {
  padding: 10px;
  padding-bottom: 250px;
}

.ordered-heading {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #202020;
  font-weight: 800;
  text-align: center;
  padding: 20px 0;
}

.ordered-tabContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.ordered-tab {
  flex: 1;
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid #ababab;
  background-color: transparent;
  cursor: pointer;
  font-weight: 400;
  font-size: 26px;
}

.ordered-activeTab {
  border-bottom: 4px solid #fd7e4b;
  color: #f67939;
}

.ordered-card {
  /* background-color: #fff; */
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 50px;
}

.ordered-cardContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ordered-imageContainer {
  width: 130px;
  height: 140px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ordered-image {
  width: 120px;
  height: 130px;
  object-fit: contain;
  border-radius: 8px;
}

.ordered-textContainer {
  flex: 1;
}

.ordered-orderId,
.ordered-itemCount {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  font-family: Arial, Helvetica, sans-serif;
}

.ordered-status {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.ordered-statusIcon {
  margin-left: 8px;
  color: #f67939;
}

.ordered-arrowIcon {
  font-size: 24px;
  color: #fd7e4b;
  cursor: pointer;
}

.ordered-emptyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.ordered-emptyText {
  font-size: 18px;
  color: #ababab;
  text-align: center;
  margin: 50px 0;
}

.orders-header {
  display: flex;
  justify-content: space-between;
  margin: 16px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .ordered-card {
    margin: 10px;
    padding: 15px;
  }

  .ordered-cardContent {
    flex-direction: column;
    align-items: flex-start;
  }

  .ordered-imageContainer {
    width: 100px;
    height: 110px;
  }

  .ordered-image {
    width: 90px;
    height: 100px;
  }

  .ordered-orderId, .ordered-itemCount {
    font-size: 16px;
  }

  .ordered-tab {
    font-size: 22px;
  }

  .ordered-timestamp {
    font-size: 14px;
  }

  .ordered-arrowIcon {
    font-size: 20px;
  }
}

/* Smaller mobile screens (e.g., 480px or less) */
@media (max-width: 480px) {
  .ordered-card {
    margin: 5px;
    padding: 10px;
  }

  .ordered-cardContent {
    flex-direction: column;
    align-items: flex-start;
  }

  .ordered-imageContainer {
    width: 80px;
    height: 90px;
  }

  .ordered-image {
    width: 70px;
    height: 80px;
  }

  .ordered-orderId, .ordered-itemCount {
    font-size: 14px;
  }

  .ordered-timestamp {
    font-size: 12px;
  }

  .ordered-tab {
    font-size: 18px;
  }

  .ordered-arrowIcon {
    font-size: 18px;
  }
}

.ordered-card {
  position: relative; /* Ensure the card is a reference point for absolutely positioned children */
}

.ordered-timestamp {
  position: absolute;
  top: 10px; /* Adjust this value to position it further or closer to the top */
  right: 10px; /* Adjust this value to position it further or closer to the right */
  color: #000000;
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
}
