/* Container Styling */
.partner-widget-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: "Arial", sans-serif;
    color: #4A2C1D; /* Dark brown for contrast */
    background-color: #FDF0DD; /* Matching light background */
    border-radius: 10px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 16px;
}

/* Button Base Styles */
.partner-widget-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 300px;
    margin-bottom: 10px;
}

/* Individual Button Colors */
.call-button {
    background: linear-gradient(135deg, #FD7E4B, #E96C39);
}

.whatsapp-button {
    background: linear-gradient(135deg, #25D366, #128C7E);
}

.details-button {
    background: linear-gradient(135deg, #F58549, #E86A33);
}

/* Hover Effects */
.partner-widget-button:hover {
    transform: scale(1.07);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Input Field Styling */
.partner-widget-input {
    width: 90%;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    font-family: "Arial", sans-serif;
    border: 1px solid #FD7E4B;
    border-radius: 5px;
    background-color: #FDF0DD;
    color: #4A2C1D;
    outline: none;
    margin-bottom: 10px;
}

/* Submit Button */
.partner-widget-submit {
    background-color: #FD7E4B;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    font-family: "Arial", sans-serif;
    padding: 10px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.partner-widget-submit:hover {
    background-color: #E86A33;
}

/* Form Styling */
.partner-widget-form {
    width: 100%;
    text-align: center;
    margin-top: 16px;
}

/* Responsive Design */
@media (max-width: 480px) {
    .partner-widget-container {
        flex-direction: column;
        align-items: center;
    }

    .partner-widget-button {
        width: 100%;
        font-size: 14px;
    }
}
