/* .slider-container {
    width: 100vw;
    height: 80px;
    overflow: hidden;
    position: relative;
    background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
} */

.marquee-slider-container {
    width: 100%;
    margin: 150px auto;
    text-align: center;
}
 
.marquee-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    height: 80px;
    width: 250px;
}

.marquee-slider-title {
    font-size: 40px;
    font-weight: 700;
    color: #333;
    margin-bottom: 80px;
    /* text-transform: uppercase; */
    font-family: Arial;
    line-height: 41px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.marquee-image {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    object-fit: contain;
    padding: 10px;
}