/* Container Styling */
.widget-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* padding: 12px; */
    font-family: "Arial", sans-serif;
    color: #4A2C1D; /* Dark brown for contrast */
    background-color: #FDF0DD; /* Matching light background */
    border-radius: 10px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
}
.widget-container {
    display: flex;
    justify-content: left;
    /* gap: 12px; */
    /* padding: 16px; */
    flex-wrap: wrap;
    /* background: linear-gradient(135deg, #FDF0DD, #f5dcb6); */
    border-radius: 8px;
}

/* Button Base Styles */
.widget-button {
    display: flex;
    align-items: center;
    justify-content: space-between;  /* Keeps text on left, icon on right */
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 12px;  /* Increased border radius */
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 300px;
}

/* Individual Button Colors */
.option-button {
    background: linear-gradient(135deg, #FD7E4B, #E96C39); 
}

/* Hover Effects */
.widget-button:hover {
    transform: scale(1.07);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Right Arrow Icon */
.arrow-icon {
    margin-left: 10px;
    font-size: 14px;
    color: white;
}

/* Responsive Design */
@media (max-width: 480px) {
    .widget-container {
        flex-direction: column;
        align-items: center;
    }

    .widget-button {
        width: 90%;
        font-size: 14px;
    }
}

/* Widget Container */
.widget-container {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px;
}

/* Input Field Styling */
.widget-input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    font-family: "Arial", sans-serif;
    border: 1px solid #FD7E4B; /* Matching main theme color */
    border-radius: 5px;
    background-color: #FDF0DD; /* Light background */
    color: #4A2C1D; /* Rich brown for contrast */
    outline: none;
}

/* Button Styling */
.widget-button {
    background-color: #FD7E4B;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    font-family: "Arial", sans-serif;
    padding: 10px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    display: flex;
    align-items: center;
    gap: 5px;
}

.widget-button-more-products {
    background-color: #FD7E4B;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    font-family: "Arial", sans-serif;
    padding: 10px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    display: flex;
    align-items: center;
    gap: 5px;
}

/* Button Hover Effect */
.widget-button:hover {
    background-color: #E86A33;
}

/* Options Widget Button */
.option-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    font-size: 14px;
    font-weight: 600;
    font-family: "Arial", sans-serif;
    background-color: #F58549;
    color: #4A2C1D;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s;
}

/* Right Arrow Icon */
.arrow-icon {
    color: #4A2C1D; /* Dark color for contrast */
    font-size: 16px;
}
.widget-container p {
    font-size: 14px;
    font-weight: 600;
    color: #6B3E26; /* Slightly darker brown for readability */
}
/* Strong Text (Headings like Order ID, Item, Status) */
.widget-container p strong {
    color: #4A2C1D; /* Dark brown for emphasis */
}

/* Status Message Styling */
.widget-container p:last-child {
    font-size: 13px;
    font-style: italic;
    color: #E86A33; /* Matching orange shade for status */
}
/* WhatsApp Button Link */
.widget-container a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background: linear-gradient(135deg, #25D366, #128C7E); /* WhatsApp green gradient */
    padding: 10px 16px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* WhatsApp Icon Spacing */
.widget-container a::before {
    content: "📲";
    margin-right: 8px;
}

/* Hover Effect */
.widget-container a:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}