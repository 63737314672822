
  .login-signup-container {
    width:100%;
    height: 100%;
    background: url('../../assets/img/auth/auth_bg.jpg') no-repeat center center;
    background-size:cover;
    position: relative;
    object-fit: contain;
  }

  .toggle-container {
    display: flex;
    justify-content: flex-start;
    padding: 20px;
    margin-left: 80px;
    padding-top: 60px;
  }

  .form-container{
    margin-left: 50px;
  }
  /* Media Queries for responsiveness */
@media screen and (max-width: 768px) {
  .form-container {
    padding: 20px;
    margin-left: 20px;
    max-width: 100%;
  }

  .toggle-container {
    margin-left: 20px;
    padding-top: 20px;
  }

  .active-category, .inactive-category {
    font-size: 32px;
  }

  .button {
    width: 100%;
  }
}

  @media screen and (max-width: 480px) {
    .active-category, .inactive-category {
      font-size: 24px;
      margin-right: 10px;
    }
  
    .toggle-container {
      flex-direction: column;
      align-items: center;
    }
  
    .button {
      width: 100%;
    }
    .form-container{
      padding: 20px;
      margin-left: 0px;
      max-width: 100%;
    }
  }
  
  